import React from 'react';
import dynamic from 'next/dynamic';
import { Frame, Pages, PageTypes } from '~/lib/data-contract';
import { Translations } from '~/shared/utils/translation';

const pageTypes: PageTypes = {
    p60ModulePage: dynamic(() => import('../P60ModulePage')),
    p90BasketPage: dynamic(() => import('../P90BasketPage')),
    p90BasketPage1: dynamic(() => import('../P90BasketPage1')),
    p140NotFoundPage: dynamic(() => import('../P140NotFoundPage')),
    p100CheckoutPage: dynamic(() => import('../P100CheckoutPage')),
    p40ProductListPage: dynamic(() => import('../PLPPage')),
    receiptPage: dynamic(() => import('../ReceiptPage')),
    p50ProductDetailsPage: dynamic(() => import('../P50ProductDetailsPage')),
    p120CreateLoginPage: dynamic(() => import('../P120CreateLoginPage')),
    p125MyProfilePage: dynamic(() => import('../P125MyProfilePage')),
    p160ArticlePage: dynamic(() => import('../P160ArticlePage')),
    articleListPage: dynamic(() => import('../P165ArticleListPage')),
    p80FavoritePage: dynamic(() => import('../P80FavoritePage')),
    p70FindStores: dynamic(() => import('../P70FindStores')),
    p71StoreDetailsPage: dynamic(() => import('../P71StoreDetailsPage')),
    brandsListPage: dynamic(() => import('../BrandsListPage')),
    p150ErrorPage: dynamic(() => import('../P150ErrorPage')),
    p10SearchPage: dynamic(() => import('../P10SearchPage')),
    giftCardBalancePage: dynamic(() => import('../GiftCardBalancePage')),
    p20FrontPage: dynamic(() => import('../P20FrontPage')),
    p170AviouPage: dynamic(() => import('../P170AviouPage')),
};

export type DynamicPageProps = {
    page: Pages;
    translations?: Translations | null;
    frame?: Frame | null;
};

export const DynamicPage = (props: DynamicPageProps) => {
    const { page } = props;
    const Page = (page.type && pageTypes[page.type]) || pageTypes.p60ModulePage;
    return <Page page={page} {...page} />;
};
